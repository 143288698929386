<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-on="on" v-bind="attrs" color="green" class="white--text">
        <v-icon size="20">mdi-plus</v-icon>
        {{ $t('add subject') }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-center card-title__heading">
        <h2>{{ $t('add subject') }}</h2>
      </v-card-title>
      <v-card-text>
        <v-text-field :label="$t('subject name')" v-model="newSubject.name" outlined dense color="#757575"
          type="text"></v-text-field>
        <v-autocomplete :loading="loadingGradeTypes" v-model="newSubject.grade_type_id" outlined dense
          :items="getGradeTypes" :label="$t('class type')" item-text="name" item-value="id"
          color="#757575"></v-autocomplete>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="green" class="white--text" @click="submit" :loading="submitBtnLoading">{{ $t('storage') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      submitBtnLoading: false,
      dialog: false,
      newSubject: {
        name: "",
        grade_type_id: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      getGradeTypes: "gradeType/getGradeTypes",
      loadingGradeTypes: "gradeType/getGradeTypeLoading",
    }),
  },
  methods: {
    async submit() {
      try {
        this.submitBtnLoading = true;
        await axios.post("/subject", this.newSubject);
        this.$Notifications(
          this.$t('add success'),
          { rtl: true, timeout: 2000 },
          "success"
        );
        this.$emit("submitted");
        this.dialog = false;
      } catch (err) {
      } finally {
        this.submitBtnLoading = false;
      }
    },
  },
};
</script>