<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-icon color="info" v-on="on" v-bind="attrs">mdi-pencil</v-icon>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-center card-title__heading">
        <h2>{{ $t("edit") }} {{ recordData.name }}</h2>
      </v-card-title>
      <v-card-text>
        <v-text-field
          :label="$t('subject name')"
          v-model="subject.name"
          outlined
          dense
          color="#757575"
          type="text"
        ></v-text-field>
        <v-autocomplete
          :loading="loadingGradeTypes"
          v-model="subject.grade_type_id"
          outlined
          dense
          :items="getGradeTypes"
          :label="$t('class type')"
          item-text="name"
          item-value="id"
          color="#757575"
        ></v-autocomplete>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          color="green"
          class="white--text"
          @click="submit"
          :loading="submitBtnLoading"
          >{{ $t("edit") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      submitBtnLoading: false,
      dialog: false,
      subject: {
        name: "",
        grade_type_id: null,
      },
    };
  },
  props: {
    recordData: Object,
  },
  computed: {
    ...mapGetters({
      getGradeTypes: "gradeType/getGradeTypes",
      loadingGradeTypes: "gradeType/getGradeTypeLoading",
    }),
  },
  methods: {
    async submit() {
      try {
        this.submitBtnLoading = true;
        await axios.put(`/subject/${this.recordData.id}`, this.subject);
        this.$Notifications(
          $t("add succcess"),
          { rtl: true, timeout: 2000 },
          "success"
        );
      } catch (err) {
      } finally {
        this.submitBtnLoading = false;
        this.$emit("submitted");
        this.dialog = false;
      }
    },
  },
  created() {
    this.subject.name = this.recordData.name;
    this.subject.grade_type_id = this.recordData.grade_type.id;
  },
};
</script>
