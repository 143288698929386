<template>
  <v-container>
    <v-card elevation="0" class="pt-5">
      <v-container>
        <v-row>
          <v-col md="2" class="mr-2" v-if="returnAbility('subject:store')">
            <app-add-subject @submitted="fetchData(1)"></app-add-subject>
          </v-col>
          <v-col md="3">
            <v-text-field
            :dark="$store.state.isDarkMode"
              v-model="name"
              :label="$t('search by name')"
              dense
              outlined
              hide-details
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-autocomplete
            :dark="$store.state.isDarkMode"
              color="#757575"
              :loading="loadingGradeTypes"
              hide-details
              v-model="grade_type_id"
              outlined
              dense
              :items="getGradeTypes"
              :label="$t('search by class type')"
              item-text="name"
              item-value="id"
              clearable
              return-object
            ></v-autocomplete>
          </v-col>
          <v-col md="3" class="d-flex justify-center align-center">
            <v-icon color="var(--bg-font--color)" class="mr-2" @click="fetchData(1)">mdi-magnify</v-icon>
          </v-col>
        </v-row>
      </v-container>

      <app-base-table
        :tableOptions="tableOptions"
        @re-fetch-data="fetchData"
        @re-fetch-paginated-data="fetchData($event)"
        serverRoute="/subject"
        :enableDelete="displayDeleteIcon"
      >
        <template slot="editFeature" slot-scope="{ row }">
          <v-icon color="green" @click="$router.push(`subject/${row.id}`)"
            >mdi-eye</v-icon
          >
          <app-edit-subject
            v-if="returnAbility('subject:update')"
            @submitted="fetchData(1)"
            :recordData="row"
          ></app-edit-subject>
        </template>
      </app-base-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import addSubject from "./addSubject.vue";
import editSubject from "./editSubject.vue";
export default {
  components: {
    appEditSubject: editSubject,
    appAddSubject: addSubject,
  },
  data() {
    return {
      submitBtnLoading: false,
      // filter
      name: "",
      grade_type_id: Number(this.$route.query.grade_type_id),
    };
  },
  computed: {
    ...mapGetters({
      getGradeTypes: "gradeType/getGradeTypes",
      loadingGradeTypes: "gradeType/getGradeTypeLoading",
      tableOptions: "subjects/getTableOptions",
    }),
  },
  methods: {
    ...mapActions({
      fetchSubjects: "subjects/fetchSubjects",
      fetchGradeTypes: "gradeType/fetchAllGradeTypes",
    }),

    fetchData(page) {
      this.fetchSubjects({
        page,
        name: this.name,
        grade_type_id: this.grade_type_id.id,
      });
    },
  },
  created() {
    if (this.returnAbility("subject:destroy")) {
      this.displayDeleteIcon = true;
    }
    if(this.returnAbility("grade-type:index")){
      this.fetchGradeTypes();
    }
    this.fetchData(1);
  },
};
</script>

<style></style>
